export default class Affiliate {
  id!: number
  member_number!: number
  date!: Date
  next_payment!: number
  safe!: string
  share!: string
  status!: string
  dependent!: string
  rate_type!: string
  balance_fee!: number
  salary!: number
  automatic_charge!: string
}
